<template>
	<div class="min-h-screen bg-black flex flex-col justify-center py-12 sm:px-6 lg:px-8">
		<div class="sm:mx-auto sm:w-full sm:max-w-md text-white">
			<img alt="Conduction PTY LTD" class="h-32 w-auto mx-auto mb-12 mt-" src="/logo.svg" />
			<h2 class="my-6 text-3xl font-extrabold text-center">Super security check!</h2>
			<p class="text-base italic text-center">Looks like you've setup 2FA on you're account (You legend!).<br />Enter the code from your 2FA app or that we've sent to you.</p>
		</div>

		<div v-if="message" class="mt-6 relative text-center text-red-500">
			<span>{{ message }}</span>
		</div>

		<div v-if="successMessage" class="mt-6 relative text-center text-green-500">
			<span>{{ successMessage }}</span>
		</div>

		<div class="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
			<div class="bg-black py-8 px-4 shadow sm:rounded-lg sm:px-10 text-white">
				<FormKit
					id="twoFactorChallenge"
					v-model="formData"
					type="form"
					:sections-schema="{
						messages: {
							attrs: {
								class: { 'w-full': true, flex: true, 'items-center': true, 'justify-center': true, 'my-2': true },
							},
						},
					}"
					submit-label="Submit challenge"
					:submit-attrs="{
						'sections-schema': {
							prefix: loadingElement('twoFactorChallenge'),
						},
						outerClass: {
							'col-span-12': true,
							'sm:col-span-12': true,
							'lg:col-span-12': true,
							'xl:col-span-12': true,
							'mt-10': true,
						},
						inputClass:
							'$reset w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
					}"
					class="flex w-full"
					@submit="twoFactorChallenge"
				>
					<div class="grid grid-cols-12 gap-x-2 gap-y-2 sm:gap-y-4 my-4">
						<FormKitSchema :schema="twoFactorSchema" />
					</div>
				</FormKit>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { ref, watch } from 'vue';
	import { useAuthStore } from '@modules/auth/store';
	import { useRouter } from 'vue-router';
	import { submitForm } from '@formkit/vue';
	import { loadingElement } from '@modules/form/utils/elements';
	import { useUserStore } from '@modules/user/store';
	import { FormKitNode } from '@formkit/core';

	const authStore = useAuthStore();
	const router = useRouter();

	const message = ref('');
	const successMessage = ref('');
	const formData = ref({
		otp: '',
	});

	const twoFactorSchema = [
		{
			$formkit: 'otp',
			name: 'otp',
			digits: 6,
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
	];

	watch(
		() => formData.value,
		(newValue) => {
			if (newValue.otp && newValue.otp.length == 6 && newValue.otp.match(/\d/g)?.join('') == newValue.otp) {
				submitForm('twoFactorChallenge');
			}
		},
		{
			deep: true,
		}
	);

	type OtpForm = {
		otp: string;
	};

	const twoFactorChallenge = async (otpForm: OtpForm, node: FormKitNode) => {
		return new Promise((r) => {
			authStore
				.challenge(otpForm.otp)
				.then((response) => {
					if (!response) {
						node.setErrors(['Sorry! It looks like we are having some problems!']);
					} else {
						const userStore = useUserStore();
						userStore.getLoggedInUser();
						router.push({
							name: 'dashboard',
						});
					}
				})
				.catch((error) => {
					if (error.message == 'Failed to fetch') {
						node.setErrors(['Sorry! It looks like we are having some problems!']);
					} else {
						node.setErrors([error.message]);
					}
					r('');
				});
		});
	};
</script>
